@media (min-width:375px)  { 

    .modal{
      width: 260px;
      /* background-color: aqua; */
    }
  
   }
   @media (min-width:390px)  { 

    .modal{
      width: 275px;
      /* background-color: aqua; */
    }
  
   }
   @media (min-width:400px)  { 

    .modal{
      width: 285px;
      /* background-color: aqua; */
    }
  
   }
   @media (min-width:410px)  { 

    .modal{
      width: 300px;
      /* background-color: aqua; */
    }
  
   }

   @media (min-width:1000px)  { 

    .modal{
      width:450px;
    }
  
   }